<template>
  <v-layout align-center justify-center bg v-if="showPage">
    <v-flex xs10 sm10 md5 lg3 xl3>
      <v-card>
        <v-toolbar color="primary" dark>
          <v-toolbar-title>GS1 Georgia</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-card-text>
          <template v-if="!showEmail">
            <v-form ref="form" v-model="valid">
              <v-text-field
                :label="$vuetify.lang.t('$vuetify.input.username')"
                autocomplete="off"
                prepend-icon="mdi-account"
                type="text"
                v-model="form.username"
                :rules="usernameRules"
                required
                @keyup.enter="login"
              ></v-text-field>

              <v-text-field
                :label="$vuetify.lang.t('$vuetify.input.password')"
                autocomplete="off"
                prepend-icon="mdi-lock"
                type="password"
                v-model="form.password"
                :rules="passwordRules"
                required
                @keyup.enter="login"
              ></v-text-field>
            </v-form>
          </template>
          <template v-else>
            {{ $vuetify.lang.t("$vuetify.errors.not_verified") }}
            {{ this.emailError.msg }}
            <v-text-field
              class="mt-3"
              :label="$vuetify.lang.t('$vuetify.company.email')"
              v-model="email"
              :error="emailError.show"
              :error-messages="emailError.msg"
              @keyup="validateEmail"
            ></v-text-field>
          </template>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <template>
            <v-btn
              :disabled="!valid"
              @click="!showEmail ? login() : resend()"
              color="primary"
            >
              {{
                !showEmail
                  ? $vuetify.lang.t("$vuetify.buttons.login")
                  : $vuetify.lang.t("$vuetify.action.send")
              }}
            </v-btn>
          </template>
        </v-card-actions>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
import Auth from "@/helpers/authentication";
export default {
  data: function () {
    return {
      btnLoading: false,
      valid: false,
      usernameRules: [
        (v) => !!v || this.$vuetify.lang.t("$vuetify.errors.required"),
      ],
      passwordRules: [
        (v) => !!v || this.$vuetify.lang.t("$vuetify.errors.required"),
      ],
      form: {
        username: "",
        password: "",
      },
      showEmail: false,
      email: null,
      emailError: {
        show: false,
        msg: null,
      },
    };
  },
  created: function () {
    Auth.clearStorage();
    const tokenPattern = /\/login\/(\S+)/;
    const token = window.location.pathname.match(tokenPattern);
    if (token && token[1]) {
      this.showPage = false;
      Auth.loginWithToken(token[1]);
    } else {
      this.showPage = true;
    }
  },
  methods: {
    validateEmail() {
      var re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z0-9]{2,}))$/;
      if (this.email !== null && this.email != "") {
        if (!re.test(this.email)) {
          this.emailError.show = true;
          this.emailError.msg = this.$vuetify.lang.t(
            "$vuetify.errors.required"
          );
          return (this.valid = false);
        } else {
          this.emailError.show = false;
          this.emailError.msg = null;
          return (this.valid = true);
        }
      }

      return (this.valid = false);
    },
    async resend() {
      await Auth.sendVerification({ email: this.email });
      this.showEmail = false;
      this.$toast.ok(this.$vuetify.lang.t("$vuetify.message.check_email"));
    },
    async login() {
      this.btnLoading = true;

      if (!this.$refs.form.validate()) {
        this.btnLoading = false;
        return false;
      }

      var loginResult = await Auth.logIn(this.form);
      if (loginResult !== true) {
        if (loginResult === "email_not_verified") {
          this.showEmail = true;
          this.valid = false;
          return false;
        }

        this.btnLoading = false;
        return false;
      }

      var role = Auth.isAuthenticated()["role"];
      var account =
        role == "manager" || role == "finance" ? "manager" : "customer";

      this.$router.push("/" + account);
    },
  },
};
</script>

<style scoped>
.bg {
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  min-height: 100vh;
  min-width: 100vw;
  overflow: hidden;
  background: #2193b0; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #6dd5ed,
    #2193b0
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #6dd5ed,
    #2193b0
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}
</style>
